import React from "react"
import { graphql, StaticQuery } from "gatsby"

// import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostCard from "../components/postCard"

// import "../utils/global.scss"
import "../utils/normalize.css"
import "../utils/css/screen.css"

const BlogIndex = ({ data }, location) => {

    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    // let postCounter = 0

    return (

        <Layout title={siteTitle}>

            <Seo
                title="Home"
                keywords={[`work`, `portfolio`, `design`, `javascript`, `ux`, `ui`]}
            />

            <header className="page-head post-content-body">

                <h1 className="page-head-title">
                    <span role="img" aria-label="Hand">👋</span>< br/>
                    Olá, seja bem-vindo!
                </h1>

                <h2>
                    Sou um designer multidisciplinar que ama resolver problemas utilizando design e tecnologia.
                </h2>

            </header>

            <div className="post-feed">

                { posts.map(({ node }) => {

                    // postCounter++

                    return (

                        <PostCard
                            key={node.fields.slug}
                            contentSlug = {`projeto`}
                            // count={postCounter}
                            count={1}
                            node={node}
                            postClass={`post`}
                        />
                    )

                })}

            </div>

            {/* <div>

                <h3>
                    <Link to={`/projetos`}>Veja mais</Link>
                </h3>

            </div> */}
            
        </Layout>
    )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
        limit: 6,
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {fields: {collection: {eq: "work"}}}) {
      edges {
        node {
          excerpt
          fields {
            slug
            collection
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            status
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
